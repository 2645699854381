function Footer() {
  return (
    <footer className="text-center">
      <p>
        Copyright &copy; 1982-{new Date().getFullYear()} {'\n'}
        Forrest Grant &bull; {'\n'}
        <a href="mailto:hello@forrestgrant.com">hello@forrestgrant.com</a> {'\n'}
        &bull; {'\n'}
        414.367.7378
      </p>
    </footer>
  )
}

export default Footer;
