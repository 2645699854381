
function Header() {
  return (
    <div className="header">
      <p className="text-center">
        <img alt="Me" className="img-circle img-thumbnail" src="/me.jpg" />
      </p>
    </div>
  )
}

export default Header;
