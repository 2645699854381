import AboutLink from './AboutLink'

function AboutWrapper() {
  return (
    <div className="about">
      <p>Hi, I&apos;m Forrest Grant.</p>
      <p>
        I&apos;m an <AboutLink link="https://linkedin.com/in/forrestgrant" text="Engineering & Product Leader" /> with ridiculous amounts of enthusiasm {'\n'}
        and help build top notch engineering teams that favor pragmatism over perfection by frequently shipping high quality software. {'\n'}
      </p>
      <p>
        When I am not building the future, you can find me <AboutLink link="http://www.instagram.com/forrestgrant" text="woodworking" />, {'\n'}
        watching <AboutLink link="http://www.patriots.com" text="football" />, or running. {'\n'}
        I love a well crafted cocktail or coffee; Whisky neat, and coffee black. {'\n'}
        According to a handful of ceramics, I&apos;m the world&apos;s greatest Father and Husband.{'\n'}
      </p>
      <p>
        If you&apos;re up for it, I&apos;d encourage you to drop a line or make a connection. {'\n'}
        <AboutLink link="mailto:hello@forrestgrant.com" text="I love talking to people" />. {'\n'}
        Just ask about the time that I placed a bet on behalf of a United States Senator.
      </p>
    </div>
  )
}

export default AboutWrapper;
