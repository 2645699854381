
function ConnectionComponent(props) {

  return (
    <div className="col-xs-2">
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        <i className={`fa ${props.icon}`} />
      </a>
    </div>
  )
}

export default ConnectionComponent;
