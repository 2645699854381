function MainComponent(props) {

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-10 col-sm-offset-1 col-lg-6 col-lg-offset-3">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default MainComponent;
