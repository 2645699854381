import ConnectionComponent from './ConnectionComponent'

function ConnectionWrapper() {
  return (
    <div className="connect row text-center">
      <ConnectionComponent link="http://www.linkedin.com/in/forrestgrant" icon="fa-linkedin" />
      <ConnectionComponent link="http://www.twitter.com/forrestgrant" icon="fa-twitter" />
      <ConnectionComponent link="http://www.github.com/forrestgrant" icon="fa-github" />
      <ConnectionComponent link="http://www.instagram.com/forrestgrant" icon="fa-instagram" />
      <ConnectionComponent link="http://www.facebook.com/forrestgrant" icon="fa-facebook" />
      <ConnectionComponent link="mailto:hello@forrestgrant.com" icon="fa-envelope" />
    </div>
  )
}

export default ConnectionWrapper;
